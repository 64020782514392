<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-tabs
      v-model="tab"
      color="primary"
      :slider-size="3"
      class="mb-2"
      v-if="tab !== undefined"
    >
      <v-tab v-for="tab in displayedTabs" :key="tab.text">
        <cz-icon :src="tab.icon" v-if="tab.icon" class="ml-2" />
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <v-toolbar outlined flat color="background">
      <v-switch
        v-if="currentTabId === 'draft-vendors'"
        v-model="showVendorsInvitedByMe"
        hide-details
      >
        <template #label>
          <span>{{ $t('customer.vendorLookup.showInvitedByMeVendors') }}</span>
        </template>
      </v-switch>
      <v-spacer />
      <cz-button
        v-if="showFilter"
        :title="$t('common.filters')"
        :icon-src="
          hasActiveFilters(filters) ? mdiFilterCheckOutline : mdiFilterOutline
        "
        :outlined="!hasActiveFilters(filters)"
        :color="hasActiveFilters(filters) ? 'accent' : 'primary'"
        @click="showFiltersBar = !showFiltersBar"
        :loading="loadingFilters"
      />
    </v-toolbar>

    <cz-data-table
      :height="'calc(100vh - 360px)'"
      v-bind="vendorsTable"
      class="mt-2"
      :columns="tableColumns"
      :options.sync="vendorsTable.options"
      @click:row="onVendorClicked"
    >
      <template #top>
        <!-- <cz-input
          :placeholder="$t('customer.vendorsSearch')"
          style="max-width: 350px"
          class="mt-4"
        /> -->
      </template>
      <template #item.primaryCategoryId="{ item }">
        <span v-if="item.primaryCategory">{{ item.primaryCategory.name }}</span>
      </template>
      <template #item.secondaryCategories="{ item }">
        <div class="d-flex flex-wrap" style="gap: 5px">
          <template v-for="(category, index) in item.secondaryCategory">
            <div class="d-flex" :key="category._id">
              <div>{{ category.name }}</div>
              <div v-if="index < item.secondaryCategory.length - 1">,</div>
            </div>
          </template>
        </div>
      </template>
      <template #item.contactInfo="{ item }">
        <cz-menu-button
          :button-title="item.contactPersonName"
          flat
          color="primary"
          :menu-items="vendorContactMenuItems(item)"
        />
      </template>
      <template #item.userId="{ item }">
        <user-info-menu :user="item.user" v-if="item.user" />
      </template>

      <template #item.createdAt="{ item }">
        <span>{{ formatDateTimeToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.actions="{ item }">
        <cz-menu-button
          v-if="vendorActions(item).length"
          clickable
          :button-icon-src="mdiDotsHorizontalCircleOutline"
          flat
          :menu-items="vendorActions(item)"
        />
      </template>
    </cz-data-table>

    <filters-bar
      v-if="filters"
      v-model="showFiltersBar"
      @close="showFiltersBar = false"
      @on-filter="onFilter"
      :filter-fields="filters"
    />
  </content-layout>
</template>

<script>
import {
  CzDataTable,
  CzInput,
  CzButton,
  CzMenuButton,
  CzIcon
} from '@/components';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import {
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiPlus,
  mdiCityVariantOutline,
  mdiShapeOutline,
  mdiShapePlusOutline,
  mdiAccountCardOutline,
  mdiDotsHorizontalCircleOutline,
  mdiDeleteOutline,
  mdiHeartOutline,
  mdiHeartOffOutline
} from '@mdi/js';
import {
  formatFiltersForServer,
  hasActiveFilters
} from '@/shared/services/filters.service';
import {
  getVendors,
  getInvitations,
  updateUser,
  deleteInvitation
} from '@/core/api';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import cities from '@/data/israeli-cities.json';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import { userHasOneOfRolesWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';

export default {
  name: 'VendorsLookupPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    FiltersBar: () => import('@/domain/shared/components/FiltersBar.vue'),
    CzDataTable,
    CzInput,
    CzButton,
    CzIcon,
    CzMenuButton,
    UserInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapFields('vendor', ['primaryCategories', 'secondaryCategories']),
    ...mapFields('auth', ['accountId', 'currentUser']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          handler: () => {
            this.$router.go(-1);
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.vendorsList'),
          disabled: true,
          exact: true
        }
      ];
    },
    displayedTabs() {
      return this.tabs.filter((item) => item.isVisible());
    },
    currentTabId() {
      return this.tabs[this.tab].id;
    },
    showFilter() {
      return this.tabs[this.tab].showFilter;
    },
    tableColumns() {
      if (
        this.currentTabId === 'active-vendors' ||
        this.currentTabId === 'draft-vendors' ||
        this.currentTabId === 'favorites-vendors'
      ) {
        return [
          {
            text: this.$t('customer.orders.vendorId'),
            value: '_id',
            sortable: true
          },
          {
            text: this.$t('customer.orders.vendorName'),
            value: 'businessName',
            sortable: true
          },
          {
            text: this.$t('vendor.onboarding.primaryCategory'),
            value: 'primaryCategoryId',
            sortable: true
          },
          {
            text: this.$t('vendor.onboarding.secondaryCategory'),
            value: 'secondaryCategories',
            sortable: false
          },
          {
            text: this.$t('vendor.onboarding.address'),
            value: 'address',
            sortable: false
          },
          {
            text: this.$t('vendor.onboarding.businessContactInfo'),
            value: 'contactInfo',
            sortable: false
          },
          {
            text: this.$t('common.actions'),
            value: 'actions',
            sortable: false
          }
        ];
      } else if (this.currentTabId === 'pending-vendors') {
        return [
          {
            text: this.$t('customer.userManagement.userEmail'),
            value: 'inviteeEmail',
            sortable: true
          },
          {
            text: this.$t('customer.userManagement.invitedBy'),
            value: 'userId',
            sortable: true
          },
          {
            text: this.$t('customer.userManagement.invitedAt'),
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('common.actions'),
            value: 'actions',
            sortable: false
          }
        ];
      }
    }
  },
  created() {
    this.tab = this.selectedTab;
    this.initializeFilters();
  },
  data() {
    return {
      mdiHeartOutline,
      mdiHeartOffOutline,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      mdiDotsHorizontalCircleOutline,
      mdiPlus,
      loading: false,
      showFiltersBar: false,
      loadingFilters: false,
      filters: null,
      initialTabSelected: false,
      showVendorsInvitedByMe: true,
      tab: undefined,
      tabs: [
        {
          id: 'active-vendors',
          text: this.$t('customer.vendorLookup.activeVendors'),
          status: ['active', 'pending_docs_update'],
          showFilter: true,
          isVisible: () => true
        },
        {
          id: 'draft-vendors',
          text: this.$t('customer.vendorLookup.draftVendors'),
          status: ['draft'],
          showFilter: true,
          isVisible: () => true
        },
        {
          id: 'pending-vendors',
          text: this.$t('customer.vendorLookup.invitedVendors'),
          showFilter: false,
          isVisible: () => true
        },
        {
          id: 'favorites-vendors',
          text: this.$t('customer.vendorLookup.favoritesVendors'),
          showFilter: true,
          icon: mdiHeartOutline,
          isVisible: () => {
            return this.currentUser?.favoritesVendorsIds?.length > 0;
          }
        }
      ],
      vendorsTable: {
        items: [],

        serverItemsLength: -1,
        options: {},
        items: [],
        loading: false
      }
    };
  },
  methods: {
    formatDateTimeToDisplay,
    ...mapActions('vendor', ['loadCategories']),
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    hasActiveFilters,
    async loadData() {
      if (this.currentTabId === 'pending-vendors') {
        this.loadPendingVendors();
      } else {
        this.loadVendors();
      }
    },
    async loadPendingVendors() {
      const { itemsPerPage, page, sortBy, sortDesc } =
        this.vendorsTable.options;

      const $skip = (page - 1) * itemsPerPage;

      const query = {
        accountId: this.accountId,
        redeem: false,
        $limit: itemsPerPage,
        $skip,
        $populate: ['user']
      };

      if (sortBy?.length) {
        query.$sort = {
          [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
        };
      }

      try {
        this.vendorsTable.loading = true;
        this.vendorsTable.items = [];
        const { data, total } = await getInvitations(query);
        this.vendorsTable.items = data;
        this.vendorsTable.serverItemsLength = total;
      } finally {
        this.vendorsTable.loading = false;
      }
    },
    async loadVendors() {
      const { itemsPerPage, page, sortBy, sortDesc } =
        this.vendorsTable.options;

      const $skip = (page - 1) * itemsPerPage;

      const query = {
        $limit: itemsPerPage,
        $skip,
        $populate: ['categories'],
        isTest: false
      };

      query.status = { $in: this.tabs[this.tab].status };

      if (
        this.currentTabId === 'draft-vendors' &&
        this.showVendorsInvitedByMe
      ) {
        query.customerAccountId = this.accountId;
      } else if (this.currentTabId === 'favorites-vendors') {
        query._id = { $in: this.currentUser.favoritesVendorsIds };
      }

      if (sortBy?.length) {
        query.$sort = {
          [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
        };
      }

      if (this.filters?.length) {
        const formattedFilters = formatFiltersForServer(
          cloneDeep(this.filters)
        );
        formattedFilters.forEach((field) => {
          query[field.fieldname] = field.value;
        });
      }

      try {
        this.vendorsTable.loading = true;
        this.vendorsTable.items = [];

        const { data, total } = await getVendors(query);
        this.vendorsTable.serverItemsLength = total;
        this.vendorsTable.items = data;
      } finally {
        this.vendorsTable.loading = false;
      }
    },
    async initializeFilters() {
      try {
        this.loadingFilters = true;
        await this.loadCategories();
      } catch (error) {
      } finally {
        this.loadingFilters = false;
      }

      const filters = [
        {
          fieldname: 'primaryCategoryId',
          label: this.$t('customer.vendorLookup.filters.primaryCategory'),
          type: 'autocomplete',
          multiple: false,
          itemText: 'name',
          operator: 'in',
          itemValue: '_id',
          items: this.primaryCategories || [],
          icon: mdiShapeOutline,
          value: null
        },
        {
          fieldname: 'secondaryCategoryId',
          label: this.$t('customer.vendorLookup.filters.secondaryCategory'),
          type: 'tags',
          itemText: 'name',
          itemValue: '_id',
          operator: 'in',
          itemsHandler: (fields) => {
            const primaryCategory = fields.find(
              (item) => item.fieldname === 'primaryCategoryId'
            );
            if (primaryCategory?.value) {
              return this.secondaryCategories.filter(
                (item) => item.categoryId === primaryCategory.value
              );
            }
            return [];
          },
          disabledHandler: (fields) => {
            const primaryCategory = fields.find(
              (item) => item.fieldname === 'primaryCategoryId'
            );
            return primaryCategory?.value ? false : true;
          },
          icon: mdiShapePlusOutline,
          value: []
        },
        {
          fieldname: 'city',
          label: this.$t('customer.vendorLookup.filters.city'),
          type: 'tags',
          itemText: 'name',
          itemValue: 'name',
          icon: mdiCityVariantOutline,
          operator: 'in',
          items: cities,
          value: []
        }
      ];

      if (this.currentTabId === 'active-vendors') {
        filters.unshift({
          fieldname: '_id',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        });
      }

      this.filters = cloneDeep(filters);
    },
    onFilter(filters) {
      this.showFiltersBar = false;
      if (!isEqual(this.filters, filters)) {
        if (this.vendorsTable?.options) {
          this.vendorsTable.options.page = 1;
        }
        this.filters = cloneDeep(filters);
        this.loadData();
      }
    },
    onVendorClicked(vendor) {
      this.$router.push({
        name: 'customer-vendor-details',
        params: {
          id: vendor._id
        }
      });
    },
    vendorContactMenuItems(vendor) {
      return [
        {
          title: this.$t('vendor.onboarding.contactPersonName'),
          subtitle: vendor.contactPersonName
        },
        {
          title: this.$t('common.email'),
          subtitle: vendor.contactEmail
        },
        {
          title: this.$t('vendor.onboarding.phone'),
          subtitle: vendor.contactPhoneNumber
        }
      ];
    },
    vendorActions(vendor) {
      if (this.currentTabId === 'pending-vendors') {
        if (
          userHasOneOfRolesWithName(this.currentUser, [
            RoleType.CUSTOMER_ADMIN
          ]) ||
          (userHasOneOfRolesWithName(this.currentUser, [
            RoleType.CUSTOMER_PURCHASER,
            RoleType.CUSTOMER_AUTHORIZER
          ]) &&
            vendor.userId === this.currentUser._id)
        ) {
          return [
            {
              title: this.$t('customer.orders.cancelOrder'),
              icon: mdiDeleteOutline,
              handler: () => {
                this.cancelInvitation(vendor);
              }
            }
          ];
        } else {
          return [];
        }
      } else {
        if (!this.currentUser.favoritesVendorsIds?.includes(vendor._id)) {
          return [
            {
              title: this.$t('common.addToFavorites'),
              icon: mdiHeartOutline,
              handler: () => {
                this.addVendorToFavorites(vendor);
              }
            }
          ];
        } else {
          return [
            {
              title: this.$t('common.removeFromFavorites'),
              icon: mdiHeartOffOutline,
              handler: () => {
                this.removeVendorFromFavorites(vendor);
              }
            }
          ];
        }
      }
    },
    clearCurrentFilters() {
      this.filters?.forEach((item) => {
        item.value = '';
      });
    },
    async addVendorToFavorites(vendor) {
      try {
        await updateUser(this.currentUser._id, {
          $push: {
            favoritesVendorsIds: vendor._id
          }
        });

        if (!this.currentUser.favoritesVendorsIds) {
          this.$set(this.currentUser, 'favoritesVendorsIds', [vendor._id]);
        } else {
          this.currentUser.favoritesVendorsIds.push(vendor._id);
        }
        this.showSuccessToastMessage(
          this.$t('common.vendorAddedToFavoritesSucess', [vendor.businessName])
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      }
    },
    async removeVendorFromFavorites(vendor) {
      try {
        await updateUser(this.currentUser._id, {
          $pull: {
            favoritesVendorsIds: vendor._id
          }
        });
        this.showSuccessToastMessage(
          this.$t('common.vendorRemovedToFavoritesSucess', [
            vendor.businessName
          ])
        );

        const index = this.currentUser.favoritesVendorsIds.findIndex(
          (item) => item === vendor._id
        );
        if (index !== -1) {
          this.currentUser.favoritesVendorsIds.splice(index, 1);
        }
      } catch (error) {
        this.showErrorToastMessage(error.message);
      }
    },
    async cancelInvitation(invite) {
      try {
        await deleteInvitation(invite._id, this.accountId);
        const index = this.vendorsTable.items.findIndex(
          (item) => item._id === invite._id
        );
        if (index !== -1) {
          this.vendorsTable.items.splice(index, 1);
          this.showSuccessToastMessage(
            this.$t('customer.vendorLookup.invitationCancelled')
          );
        }
      } finally {
      }
    }
  },
  watch: {
    'vendorsTable.options': {
      handler(value) {
        this.loadData();
      }
    },
    showVendorsInvitedByMe: {
      handler() {
        this.loadData();
      }
    },
    showFiltersBar: {
      async handler(value) {
        if (value && this.filters === null) {
          this.initializeFilters();
        }
      }
    },
    tab: {
      handler() {
        if (this.initialTabSelected) {
          this.clearCurrentFilters();
          this.initializeFilters();
          this.loadData();
        }

        this.initialTabSelected = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
